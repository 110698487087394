import React, { useState } from "react"
import Img from "gatsby-image"

import Layout from "../components/layout"
import Header from "../components/header"
import SEO from "../components/seo"
import { Flex, Box } from "reflexbox"
import styled from "styled-components"

import IntercomLogo from "../images/intercom.svg"
import AwkwardLogo from "../images/awkie.svg"
import BundleLogo from "../images/bundle.svg"
import YummygumLogo from "../images/yummygum.svg"
import TwitterLogo from "../images/twitter.svg"
import InstagramLogo from "../images/instagram.svg"
import LinkedInLogo from "../images/linkedin.svg"
import DribbbleLogo from "../images/dribbble.svg"
import IntercomLauncher from "../images/intercom-launcher.svg"

const StyledCard = styled(Box)`
  background-color: #fff;
  border-radius: 6px;
  color: #313a43;
  box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.06),
    0 13px 13px 0 rgba(0, 0, 0, 0.08), 0 20px 15px 0 rgba(0, 0, 0, 0.04);
  padding: 24px;
  min-height: 286px;
  overflow: hidden;
  position: relative;
  transition: 0.25s transform ease-in-out;

  * {
    transition: 0.25s transform ease-in-out, 0.25s opacity ease-in-out,
      0.25s filter ease-in-out, 0.2s background-color ease-in-out;
  }

  &#IconJar {
    background-color: #f2f9fe;

    h3,
    p {
      position: relative;
      z-index: 3;
    }

    .folder {
      top: 0;
      z-index: 1;
      position: absolute !important;
      will-change: filter;
      filter: blur(0px);

      &.f1 {
        top: 129px;
        left: -116px;
      }
      &.f2 {
        top: 55px;
        left: 286px;
      }
      &.f3 {
        top: 21px;
        right: -75px;
      }
      &.f4 {
        top: -21px;
        left: 149px;
      }
    }
  }

  &#Intercom {
    background-color: #f4f0eb;

    .preview {
      background-color: rgba(255, 255, 255, 0.5);
      box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.06),
        0 13px 13px 0 rgba(0, 0, 0, 0.08), 0 20px 15px 0 rgba(0, 0, 0, 0.04);
      border: 1px solid rgba(0, 0, 0, 0.03);
      border-radius: 6px;
      position: absolute !important;
      height: 232px;
      left: -32px;
      right: 24px;
      top: -148px;
      transform: rotate(3deg);

      svg {
        position: absolute;
        right: 16px;
        bottom: 16px;
      }
    }
  }

  @media (hover: hover) {
    &#IconJar:hover,
    &#Intercom:hover {
      transform: scale(1.02);
    }

    &#Intercom:hover {
      .preview {
        background-color: #fff;
        transform: rotate(4deg) translate(8px, 8px);

        svg {
          transform: scale(1.1);
        }
      }
    }

    &:hover {
      .f1 {
        transform: scale(1.2) translateX(-16px);
        filter: blur(5px);
      }
      .f2 {
        transform: scale(1.2);
        filter: blur(2px);
        opacity: 0.85;
      }
      .f3 {
        transform: scale(1.1) translate(0 25px);
        opacity: 0.7;
        filter: blur(5px);
      }
      .f4 {
        transform: scale(1.2) translate(16px -16px);
        filter: blur(2px);
      }
    }
  }
`

const StyledSpacer = styled(Box)`
  width: 48px;
`

const StyledBadge = styled.span`
  display: inline-block;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 3px;
  font-size: 14px;
  line-height: 24px;
  padding-left: 8px;
  padding-right: 8px;
  margin-right: 8px;

  &:last-of-type {
    margin-right: 0;
  }
`

const StyledSocialLink = styled.a`
  overflow: hidden;
  margin-right: 16px;

  span {
    display: none;
  }
`

const StyledPhotoBg = styled(Box)`
  position: absolute;
  top: 0;
  right: 0;
  right: 0;
  opacity: 1;
  mask-image: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 1) 25%,
    rgba(0, 0, 0, 0)
  );

  .gatsby-image-wrapper {
    mask-image: linear-gradient(
      to left,
      rgba(0, 0, 0, 1) 25%,
      rgba(0, 0, 0, 0)
    );
    transition: 0.25s all ease-in-out;
  }
`

const StyledButton = styled.a`
  color: #fff;
  font-family: "SpaceGroteskSemiBold";
  padding: 10px 16px;
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(0, 0, 0, 0.05);
  border: 1px solid rgba(255, 255, 255, 0.07);
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.02), 0 4px 2px 0 rgba(0, 0, 0, 0.02),
    0 8px 4px 0 rgba(0, 0, 0, 0.02);
  border-radius: 6px;
  transition: 0.2s all ease-in-out;

  &:hover {
    background-color: #fff;
    color: #2b2c2f;
  }
`

const IndexPage = ({ data }) => {
  const [background, setBackground] = useState("selfie")

  function updateBackground(value) {
    setBackground(value)
  }

  return (
    <>
      <Layout>
        <SEO title="Davey Heuser's product design portfolio" />

        <Header />

        <Flex py={5}>
          <Box width={[1, 1 / 2]}>
            <h1>
              I'm a generalist who aspires to create <br />
              <a
                className="inline headline"
                href="https://www.intercom.com/blog/understanding-your-aha-moments-and-putting-them-to-work/"
              >
                <i>&ldquo;aha moments&rdquo;</i>
              </a>{" "}
              through great product design
            </h1>
            <p className="intro">
              Over the past ~ 10 years I’ve learned to apply an opportunistic
              mindset to unpack difficult problems and create end-to-end
              customer experiences. I believe good outcomes are achieved through
              a shared mission and vision, great collaboration, meaningful
              trade-offs and high quality output.
            </p>
            <p className="intro">
              I get inspired by{" "}
              <a
                href="https://vimeo.com/55073825"
                onMouseOver={() => updateBackground("overviewEffect")}
                onFocus={() => updateBackground("overviewEffect")}
                onMouseOut={() => updateBackground("selfie")}
                onBlur={() => updateBackground("selfie")}
                className="inline"
              >
                the overview effect
              </a>{" "}
              and the architecture of buildings or landscapes. When I’m not at
              my desk I make a proper coffee,{" "}
              <a
                href="https://instagram.com/daveyheuser"
                onMouseOver={() => updateBackground("photography")}
                onFocus={() => updateBackground("photography")}
                onMouseOut={() => updateBackground("selfie")}
                onBlur={() => updateBackground("selfie")}
                className="inline"
              >
                go out with my camera
              </a>{" "}
              or cycle.
            </p>
          </Box>
        </Flex>

        {/* Current roles */}
        <Box width={1} py={[4, 5]}>
          <h2>Current roles</h2>
          <Flex flexWrap={["wrap", "inherit"]}>
            <Box as="a" href="https://geticonjar.com" width={[1, 1 / 2]}>
              <StyledCard id="IconJar" mb={[4, 0]}>
                <Box mt={2}>
                  <Img
                    fixed={data.iconjar.childImageSharp.fixed}
                    style={{ marginBottom: "16px" }}
                  />
                  <h3>Co-founder &amp; designer of IconJar for macOS</h3>
                  <p>
                    IconJar leaves digging through design resources behind so
                    people can use their icons without hassle.
                  </p>
                </Box>
                {/* Folder icons */}
                <Img
                  fixed={data.folder1.childImageSharp.fixed}
                  className="folder f1"
                />
                <Img
                  fixed={data.folder2.childImageSharp.fixed}
                  className="folder f2"
                />
                <Img
                  fixed={data.folder3.childImageSharp.fixed}
                  className="folder f3"
                />
                <Img
                  fixed={data.folder4.childImageSharp.fixed}
                  className="folder f4"
                />
              </StyledCard>
            </Box>
            <StyledSpacer></StyledSpacer> {/* 48px gutter */}
            <Box as="a" href="https://intercom.com" width={[1, 1 / 2]}>
              <StyledCard id="Intercom">
                <div className="preview">
                  <IntercomLauncher />
                </div>
                <Box mt={5} pt={3}>
                  <Box mb={3}>
                    <IntercomLogo />
                  </Box>
                  <h3>Product designer at Intercom</h3>
                  <p>
                    I help Intercom on their quest to make internet business
                    personal at scale.
                  </p>
                </Box>
              </StyledCard>
            </Box>
          </Flex>
        </Box>

        {/* Past roles */}
        <Box width={1} py={[4, 5]}>
          <h2>Past roles</h2>
          <Flex flexWrap={["wrap", "inherit"]}>
            <Box width={[1, 1 / 3]} mb={[4, 0]}>
              <Flex mb={3} alignItems="center" style={{ height: "52px" }}>
                <AwkwardLogo />
              </Flex>
              <h4>
                <a href="https://awkward.co">Awkward</a>
              </h4>
              <StyledBadge>All-round designer</StyledBadge>
              <StyledBadge>Mid 2016 - late 2017</StyledBadge>
              <p>
                At Awkward I had the opportunity to design digital experiences
                for customers like Sketch, WeTransfer and Ace & Tate.
              </p>
            </Box>
            <StyledSpacer></StyledSpacer> {/* 48px gutter */}
            <Box width={[1, 1 / 3]} mb={[4, 0]}>
              <Flex mb={3} alignItems="center" style={{ height: "52px" }}>
                <BundleLogo />
              </Flex>
              <h4>
                <a href="https://www.crunchbase.com/organization/bundle-2">
                  Bundle
                </a>
              </h4>
              <StyledBadge>Product design intern</StyledBadge>
              <StyledBadge>Early to mid 2016</StyledBadge>
              <p>
                At Bundle I designed a solution that helped people find their
                most memorable photos in the digital age.{" "}
              </p>
            </Box>
            <StyledSpacer></StyledSpacer> {/* 48px gutter */}
            <Box width={[1, 1 / 3]}>
              <Flex mb={3} alignItems="center" style={{ height: "52px" }}>
                <YummygumLogo />
              </Flex>
              <h4>
                <a href="https://yummygum.com">Yummygum</a>
              </h4>
              <StyledBadge>Visual designer</StyledBadge>
              <StyledBadge>Early 2014 - early 2016</StyledBadge>
              <p>
                At Yummygum I helped startups and early stage companies with
                beautiful visual design for mobile and the web.
              </p>
            </Box>
          </Flex>
        </Box>

        {/* Callout */}
        <Flex pb={4} alignItems="center">
          <StyledSocialLink href="https://twitter.com/daveyheuser">
            <span>Twitter</span>
            <TwitterLogo />
          </StyledSocialLink>
          <StyledSocialLink href="https://instagram.com/daveyheuser">
            <span>Instagram</span>
            <InstagramLogo />
          </StyledSocialLink>
          <StyledSocialLink href="https://www.linkedin.com/in/daveyheuser/">
            <span>LinkedIn</span>
            <LinkedInLogo />
          </StyledSocialLink>
          <StyledSocialLink href="https://dribbble.com/daveyheuser">
            <span>Dribbble</span>
            <DribbbleLogo />
          </StyledSocialLink>
        </Flex>
      </Layout>
      <StyledPhotoBg width={[1, 1, 6 / 11]}>
        <Img
          fluid={data.selfie.childImageSharp.fluid}
          style={{ opacity: background === "selfie" ? 1 : 0 }}
        />

        <Img
          fluid={data.overviewEffect.childImageSharp.fluid}
          style={{
            opacity: background === "overviewEffect" ? 1 : 0,
            marginTop: "-133.4763948497854%",
          }}
        />

        <Img
          fluid={data.photography.childImageSharp.fluid}
          style={{
            opacity: background === "photography" ? 1 : 0,
            marginTop: "-133.4763948497854%",
          }}
        />
      </StyledPhotoBg>
    </>
  )
}

export const query = graphql`
  query {
    selfie: file(name: { eq: "selfie" }) {
      childImageSharp {
        fluid(jpegProgressive: true, grayscale: true, maxWidth: 930) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    overviewEffect: file(name: { eq: "overview-effect" }) {
      childImageSharp {
        fluid(jpegProgressive: true, grayscale: true, maxWidth: 930) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    photography: file(name: { eq: "photography" }) {
      childImageSharp {
        fluid(jpegProgressive: true, grayscale: true, maxWidth: 930) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    iconjar: file(name: { eq: "iconjar" }) {
      childImageSharp {
        fixed(width: 80, quality: 100) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
    folder1: file(name: { eq: "folder-1" }) {
      childImageSharp {
        fixed(width: 153, quality: 100) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
    folder2: file(name: { eq: "folder-2" }) {
      childImageSharp {
        fixed(width: 60, quality: 100) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
    folder3: file(name: { eq: "folder-3" }) {
      childImageSharp {
        fixed(width: 172, quality: 100) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
    folder4: file(name: { eq: "folder-4" }) {
      childImageSharp {
        fixed(width: 116, quality: 100) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
  }
`

export default IndexPage
