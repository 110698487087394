import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import { Flex, Box } from "reflexbox"

const StyledLink = styled(Link)`
  font-family: "SpaceGroteskSemiBold";
  line-height: 1.5rem;
`

const Layout = () => {
  return (
    <Box width={1} mt={4} mb={[4, 5]}>
      <Flex flexWrap="wrap">
        <Box mr={"3"}>
          <StyledLink to="./">Davey Heuser</StyledLink>
        </Box>
        <Box width={[1, 1 / 4, 1 / 5]}>
          <span>Product designer, currently at Intercom</span>
        </Box>
      </Flex>
    </Box>
  )
}

export default Layout
